<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter component
 */
export default {
  page: {
    title: "Master Data",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Master Data",
      requests: [
        
        {
          title: "Employés Internes",
          route: "master_data.referentiels.employes",
          description: "",
          icon: "architect",
          guard: ["create_employee", "edit_employee", "delete_employee", "view_employee", "list_employee"],
          disabled: false,
        },
        {
          title: "Employés Intra-Entreprise",
          route: "master_data.referentiels.internes_employes",
          description: "",
          icon: "internes_employes",
          guard: ["create_visitor", "edit_visitor", "delete_visitor", "view_visitor", "list_visitor"],
          disabled: false,
        },
         {
          title: "Employées de prestataires",
          route: "master_data.referentiels.prestataires",
          description: "",
          icon: "monitoring",
          guard: ["create_employee", "edit_employee", "delete_employee", "view_employee", "list_employee"],
          disabled: false,
        },
         {
          title: "Visiteur",
          route: "master_data.referentiels.visitors",
          description: "",
          icon: "human",
          guard: ["create_visitor", "edit_visitor", "delete_visitor", "view_visitor", "list_visitor"],
          disabled: false,
        },
         

        

      ],
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Gestion Du Personnel",
          active: true,
        }
      ],
    };
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row settings">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xs-12 col-md-3 col-lg-3 setting-item"  v-for="request in requests" v-bind:key="request.title">
                <router-link v-if="canAny(request.guard)" :to="!request.disabled ? { name: request.route } : '' " :class="request.disabled ? 'disabledItem' : ''">
                  <div class="card">
                    <div class="card-body">
                      <h4 class="card-title">
                        <img
                          :src="require('@/assets/images/base/menu/' + request.icon +'.png')"
                          :alt="request.title"
                          :title="request.title"
                          class="rounded-0"
                        />
                        <h4 class="card-title" >{{request.title}}</h4>
                      </h4>
                      <p class="card-text">
                        <!-- With supporting text below as a natural lead-in to
                        additional content. -->
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-none">
      <div
        class="col-lg-4 azmt-service-card"
        v-for="request in requests"
        v-bind:key="request.title"
      >
        <router-link v-if="canAny(request.guard)" :to="!request.disabled ? { name: request.route } : '' " :class="request.disabled ? 'disabledItem' : ''">
          <b-card no-body>
            <b-row no-gutters class="align-items-center">
              <b-col md="8">
                <b-card-body :title="request.title"> </b-card-body>
              </b-col>
              <b-col md="4">
                <b-card-img
                  :src="
                    require('@/assets/images/base/menu/' +
                      request.icon +
                      '.png')
                  "
                  :alt="request.title"
                  :title="request.title"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
            </b-row>
          </b-card>
        </router-link>
      </div>
    </div>
  </Layout>
</template>
